import React from "react"
import styles from "./Cta.module.scss"
import Button from "../Button"
import PropTypes from "prop-types"
import cta from "../../data/cta"

const Cta = ({ short, text }) => {
  const { firstRow, secondRow, link } = cta[text] ? cta[text] : cta.default;
  return (
    <div className={`${styles.cta} ${short ? styles.ctaShort : ""}`}>
      <div className={styles.text}>
        {firstRow} <br/>
        {secondRow}
      </div>
      {link && <Button link={link}>Zjistit více</Button>}
    </div>
  )
}

export default Cta

Cta.propTypes = {
  short: PropTypes.bool,
  text: PropTypes.string.isRequired,
}
