export default {
  distributors: {
    title: {
      thin: "Naši",
      bold: "dodavatelé"
    },
    desc: "Mezi naše dodavatelé patří významné splečnosti řadící se k předním společnostem v oboru."
  },
  shop: {
    title: {
      thin: "Kamenná ",
      bold: "prodejna"
    },
    button: "Zobrazit více informací"
  },
  projects: {
    title: {
      thin: "Ukázka ",
      bold: "našich realizací"
    },
    desc: "Pravidelně provádíme různorodé realizace, státní zakázky ale i práce pro koncové zákazníky. Pokud máte zájem o naše služby, neváhejte se nám ozvat. Konzultace zdarma a nezávazně.",
    button: "Zobrazit více realizací"
  },
  experience: {
    title: {
      thin: "Nabízíme ",
      bold: "bohaté zkušenosti"
    },
    desc: "V oborech voda, topení, plyn a stavební práce nabízíme bohaté zkušenosti. Zajišťujeme montáže, záruční a pozáruční servis, opravy a zkoušky vybraných plynových zařízení včetně revizí. Díky mnohaletým zkušenostem a odbornému personálu Vám nabízíme poradenství a servis na špičkové úrovni.",
    button: "Zobrazit více"
  }
}