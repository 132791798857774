export default {
  shop: {
    firstRow:"Potřebujete instalatérský materiál?",
    secondRow:"Navštivte naši plně vybavenou kamennou prodejnou s možností okamžitého nákupu.",
    link:"/prodejna"
  },
  contact: {
    firstRow: "Máte zájem o spolupráci?",
    secondRow: "Kontaktujte nás.",
  },
  velko: {
    firstRow: "Máte zájem o spolupráci?",
    secondRow: "Kontaktujte nás.",
    link:"/kontakt"
  },
  default: {
    firstRow:"Máte problém s kotlem?",
    secondRow:"Provádíme záruční i pozáruční servis kotlů Chaffoteaux, Baxi a Immergas.",
    link:"/kontakt"
  }
}