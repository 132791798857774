import React from 'react'
import PropTypes from 'prop-types';
import SwiperCore, { Navigation, Pagination, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
require('swiper/swiper.scss');
require('swiper/components/navigation/navigation.scss');
require('swiper/components/pagination/pagination.scss');
require('../styles/swiper.scss');
SwiperCore.use([Navigation, Pagination, A11y]);

const SwiperComp = ({children, hideButtons, slidesPerView, alignCenter}) => {
  return (
     <Swiper
          className={`swiper-cont ${hideButtons ? 'swiper-hide-buttons' : ''} ${alignCenter ? 'swiper-align-center' : ''}`}
          slidesPerView={slidesPerView ? slidesPerView : 4}
          breakpoints={{
            100: {
              slidesPerView: 1,
              spaceBetween: 0,
            },
            400: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            700: {
              slidesPerView: 3,
              spaceBetween: 25,
            },
            1100: {
              slidesPerView: 4,
              spaceBetween: 30,
            },
            1400: {
              slidesPerView: (slidesPerView > 4) ? 5 : 4,
              spaceBetween: 30,
            }
          }}
          navigation={{ 
            clickable: true
          }}
          pagination={{ 
            clickable: true
          }}
        >
          {children.map((child, index) => {
            return (
              <SwiperSlide key={index}>{child}</SwiperSlide>
            )
          })}
      </Swiper>
  )
}

export default SwiperComp

SwiperComp.propTypes = {
  children: PropTypes.node.isRequired,
  hideButtons: PropTypes.bool,
  alignCenter: PropTypes.bool,
  slidesPerView: PropTypes.number
}
